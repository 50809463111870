<template>
  <div class="footer-wrapper">
    <div class="container">
      <div class="desc">
        <div class="logo"></div>
        <div class="address" title="Address">
          <i class="el-icon-location-information" />
          <span
            >Room 504, Kangshan Business Center Building #16, XiSaiShan Road,
            Huzhou City, Zhejiang Province, China</span
          >
        </div>
        <div class="email" title="E-Mail">
          <i class="el-icon-message" />
          <a
            href="mailto:miya@trppc.com.cn?subject=Hello The Right Promotional Products Company&body=Hello The Right Promotional Products Company!"
            >miya@trppc.com.cn</a
          >
        </div>
      </div>
      <div class="nav">
        <div class="item">
          <div class="item-title">COMPANY</div>
          <div class="item-nav" @click="handleNav('About')">About us</div>
          <div class="item-nav">Contact us</div>
          <div class="item-nav">FAQ</div>
          <div class="item-nav">Price Offer & Shipping</div>
        </div>
        <div class="item">
          <div class="item-title">Customer Made</div>
          <div class="item-nav" @click="handleNav('Product')"
            >Offices & Desks</div
          >
          <div class="item-nav">Plaques</div>
          <div class="item-nav">Printing</div>
        </div>
        <div class="item">
          <div class="item-title">Best Seller</div>
          <div class="item-nav">Bags</div>
          <div class="item-nav">Mugs & Drinkware</div>
          <div class="item-nav">Caps & Headwear</div>
        </div>
      </div>
    </div>
    <div class="record"
      >© 2015 - 2024 The Right Promotional Products Company. All rights
      reserved</div
    >
  </div>
</template>

<script>
  export default {
    name: 'Footer',
    methods: {
      handleNav(routerName) {
        this.$router.push({ name: routerName });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .footer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 372px;
    background-color: #e6f2ea;
    .container {
      display: flex;
      justify-content: center;
      width: 100%;
      color: #28a745;
      .desc {
        display: flex;
        flex-direction: column;
        align-items: left;
        width: 400px;
        margin-right: 50px;
        .logo {
          width: 300px;
          height: 110px;
          background: url('~@/assets/logo-title.png');
          background-size: 100% 100%;
          margin: 0 0 40px 20px;
        }
        .address {
          margin: -20px 0 0 50px;
        }

        .email {
          margin: 20px 0 0 50px;
        }
        span,
        a {
          font-size: 16px;
          padding-left: 12px;
          margin-top: 8px;
          color: #28a745;
        }
        i {
          display: inline-block;
          vertical-align: middle;
          font-size: 24px;
          color: #28a745;
          font-weight: 700;
        }
      }
      .nav {
        width: 50rem;
        display: flex;
        margin: 80px 0 0 80px;
        .item {
          width: 300px;
          display: flex;
          flex-direction: column;
          justify-content: left;
          .item-title {
            color: #28a745;
            font-weight: 600;
            cursor: pointer;
            height: 40px;
            line-height: 40px;
          }
          .item-nav {
            color: #3b8043;
            cursor: pointer;
            height: 24px;
            line-height: 24px;
          }
        }
      }
    }
    .record {
      margin-top: 30px;
    }
  }
</style>
