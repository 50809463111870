<template>
  <div class="search-wrap">
    <div class="content">
      <div class="logo-content">
        <div class="logo"></div>
        <div class="company-content">
          <p
            >Design and offer an extensive selection of over 500+ promotional
            items.</p
          >
          <p
            >Specializing in scalable factory production, we supply promotional
            goods direct from China globally.</p
          >
        </div>
      </div>
      <div class="category-search">
        <div class="category">
          <el-cascader
            style="width: 250%"
            v-model="value"
            :options="ALL_CATEGORIES"
            :props="{ expandTrigger: 'hover' }"
            popper-class="category-popper"
            placeholder="please search or select ..."
            clearable
            filterable
          >
            <template slot-scope="{ data }">
              <div style="position: relative">
                <span>{{ `${data.label}(${data.picNums})` }}</span>
                <span v-if="data.new" class="cascader-new">new</span>
              </div>
            </template>
          </el-cascader>
        </div>
        <div class="tags">
          <div class="title">Popular Searches:</div>
          <div
            v-for="(item, index) in popularSearches"
            :key="index"
            class="tag"
            @click="handleClickTag(item)"
          >
            <div>{{ item.label }}</div>
          </div>
        </div>
      </div>
      <!-- <div class="opt"> -->
      <!-- 联系我们 -->
      <!-- <div class="email">
          <div class="icon">
            <i class="el-icon-message" />
          </div>
          <div class="text">
            <span class="desc">contact us for mail</span>
            <a
              class="content"
              href="mailto:miya@trppc.com.cn?subject=Hello The Right Promotional Products Company&body=Hello The Right Promotional Products Company!"
              >miya@trppc.com.cn</a
            >
          </div>
        </div> -->
      <!-- 购物车 -->
      <!-- <el-divider direction="vertical"></el-divider>
        <div class="shopping" @click="handleShowDrawer">
          <div class="count">{{ selectedNum }}</div>
          <div class="icon">
            <i class="el-icon-shopping-cart-full" />
          </div>
        </div> -->
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
  import { ALL_CATEGORIES } from './constants.js';

  export default {
    name: 'Search',
    props: {
      selectedNum: {
        type: Number,
        default: 0,
      },
    },
    watch: {
      value: function (val) {
        this.$emit('list', val);
      },
    },
    data() {
      return {
        value: [],
        ALL_CATEGORIES,
        popularSearches: [
          'Baseball Cap',
          'Sports bottle',
          'Stadium Cups',
          'Chalk Boards',
          'BBQs',
        ],
      };
    },
    mounted() {
      this.getPopular();
    },
    methods: {
      handleShowDrawer() {
        this.$emit('drawer');
      },
      getPopular() {
        const populars = [];
        ALL_CATEGORIES.forEach(item => {
          if (item.new) {
            populars.push(item);
          }
          if (item.children) {
            item.children.forEach(i => {
              if (i.new) {
                populars.push(i);
              }
            });
          }
        });
        this.popularSearches = populars;
      },
      handleClickTag(item) {
        if (item.value.includes('-')) {
          const ts = item.value.split('-');
          this.$emit('list', [ts[0], item.value]);
        } else {
          this.$emit('list', [item.value]);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .search-wrap {
    background-color: #e6f2ea;
    border-top: 1px solid rgba(59, 128, 67, 0.1);
    // border-bottom: 1px solid rgba(59, 128, 67, 0.3);
    .content {
      max-width: 1520px;
      height: 240px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
    .logo-content {
      display: flex;
      justify-content: center;
      .logo {
        width: 300px;
        height: 110px;
        background: url('~@/assets/logo-title.png');
        background-size: 100% 100%;
        margin: 10px 20px 10px 0;
      }
      .company-content {
        height: 110px;
        // margin-top: 20px;
        p {
          &:first-child {
            color: #000;
            font-size: 24px;
            font-weight: 600;
            margin: 0 auto;
            padding-top: 48px;
          }
          color: #808080; //#3b8043;
          margin-top: 4px;
        }
      }
    }

    .category-search {
      flex: 1;
      min-width: 540px;
      border-radius: 10px;
      margin-right: 40px;
      .category {
        display: flex;
        justify-content: left;
      }
      .tags {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        font-weight: 400;
        margin: 8px 4px;
        cursor: pointer;
        .title {
          font-size: 14px;
          font-weight: 600;
          margin-right: 24px;
        }
        .tag {
          display: flex;
          padding-right: 12px;
          margin-right: 12px;
          font-size: 14px;
          cursor: pointer;
          &:not(:last-child) {
            border-right: 1px solid #808080;
          }
        }
      }
    }
    ::v-deep .el-divider--vertical {
      margin-top: 28px;
    }
    .opt {
      display: flex;
      width: 350px;
      .email {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        // margin-left: 60px;
        margin-right: 10px;
        .icon {
          font-size: 48px;
          font-weight: 700;
          color: #3b8043;
          :hover {
            color: #28a745;
          }
        }
        .text {
          display: flex;
          flex-direction: column;
          justify-content: left;
          margin-left: 12px;
          cursor: pointer;
          span {
            display: block;
          }
          .desc {
            color: #3b8043;
            font-size: 14px;
          }
          .content {
            color: #28a745;
            font-size: 14px;
          }
        }
      }
      .shopping {
        position: relative;
        cursor: pointer;
        .icon {
          font-size: 48px;
          font-weight: 700;
          color: #3b8043;
          :hover {
            color: #28a745;
          }
        }
        .count {
          position: absolute;
          top: 4px;
          right: -8px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20px;
          height: 20px;
          border-radius: 20px;
          background: #28a745;
          color: #fff;
          font-size: 12px;
        }
      }
    }
  }
</style>
<style lang="scss">
  .category-popper {
    height: 520px;
    .el-cascader-panel {
      height: 100%;
      .el-cascader-menu {
        min-width: 240px;
      }
      .el-cascader-menu__wrap {
        height: 540px;
      }
    }
  }
  .cascader-new {
    position: relative;
    top: -10px;
    left: -2px;
    display: inline-block;
    width: 28px;
    height: 12px;
    line-height: 12px;
    font-size: 10px;
    border-radius: 2px;
    color: #fff;
    background: #f56c6c;
    text-align: center;
  }
</style>
