<template>
  <div class="new-product-wrapper">
    <vue-seamless-scroll
      :data="newCategories"
      :class-option="option"
      class="scroll-wrapper"
    >
      <div
        class="container"
        v-for="item in newCategories"
        :key="item.value"
        @click="handleClickNew(item)"
      >
        <!-- 四个图片 -->
        <div class="pics">
          <el-image
            class="pic"
            v-for="index in 4"
            :key="index"
            :src="getImagePath('27', item.value, index, true)"
          />
        </div>
        <!-- 介绍 -->
        <div class="desc">
          <span>{{ item.label }}</span>
        </div>
      </div>
    </vue-seamless-scroll>
    <!-- 详情 -->
    <product-detail-dialog
      :dialogVisible.sync="showDialog"
      :mainCategory="activeName"
      :subCategory="subActiveName"
      :product="activeProduct"
      @add="handleShopping"
    />
  </div>
</template>

<script>
  import VueSeamlessScroll from 'vue-seamless-scroll';
  import { ALL_CATEGORIES } from '../constants.js';
  import ProductDetailDialog from './ProductDetailDialog.vue';

  export default {
    name: 'NewProduct',
    components: {
      VueSeamlessScroll,
      ProductDetailDialog,
    },
    data() {
      return {
        option: {
          step: 1, // 数值越大速度滚动越快
          limitMoveNum: 1, // 开始无缝滚动的数据量
          hoverStop: true, // 是否开启鼠标悬停stop
          direction: 2, // 0向下 1向上 2向左 3向右
          openWatch: true, // 开启数据实时监控刷新dom
          singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
          singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
          waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
          loop: true, // 是否开启循环滚动
        },
        activeName: '27',
        subActiveName: '27-1',
        showDialog: false,
        activeProduct: null,
      };
    },
    computed: {
      newCategories() {
        const newCategory = ALL_CATEGORIES.find(item => item.value === '27');
        return newCategory.children;
      },
    },
    methods: {
      getImagePath(itemValue, iValue, picIndex, isThumb) {
        // 注意：实际环境中，你可能需要根据实际情况调整路径
        return require(
          `../../assets/image/category/${itemValue}/${iValue}/${isThumb ? 'thumb/' : ''}${iValue}-${picIndex}.jpg`
        );
      },
      handleClickNew(item) {
        this.subActiveName = item.value;
        this.activeProduct = `${item.value}-1`;
        this.showDialog = true;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .new-product-wrapper {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    width: 100%;
    height: 256px;
    margin-top: 24px;
    .container {
      display: inline-block;
      width: 240px;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      margin-right: 8px;
      cursor: pointer;
      .pics {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .pic {
          width: 100px;
          height: 100px;
          border-radius: 100px;
          margin: 5px;
        }
      }
      .desc {
        width: 240px;
        height: 36px;
        line-height: 36px;
        background: #009c00;
        font-size: 12px;
        color: #fff;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        text-align: center;
      }
    }
  }
</style>
