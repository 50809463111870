var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-wrapper"},[_c('el-tabs',{attrs:{"tab-position":"left","type":"card"},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},_vm._l((_vm.ALL_CATEGORIES),function(item,index){return _c('el-tab-pane',{key:index,attrs:{"name":item.value,"label":item.label}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('div',{staticClass:"panel-label"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(`${item.label}（${item.picNums}）`))]),(item.new)?_c('div',{staticClass:"new"}):_vm._e()])]),(item.children)?_c('div',{staticClass:"list"},[_c('el-tabs',{staticStyle:{"height":"100%","overflow-x":"auto"},attrs:{"tab-position":"top","type":"card"},model:{value:(_vm.subActiveName),callback:function ($$v) {_vm.subActiveName=$$v},expression:"subActiveName"}},_vm._l((item.children),function(i,subIndex){return _c('el-tab-pane',{key:subIndex,attrs:{"name":i.value,"label":i.label}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('div',{staticClass:"panel-label"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(`${i.label}（${i.picNums}）`))]),(i.new)?_c('div',{staticClass:"new"}):_vm._e()])]),(i.value === _vm.subActiveName)?_c('div',{staticClass:"card-list"},_vm._l((i.picNums),function(picIndex){return _c('div',{key:picIndex,staticClass:"card"},[_c('el-image',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.getImagePath(item.value, i.value, picIndex, true),"preview-src-list":[
                    _vm.getImagePath(item.value, i.value, picIndex, false),
                  ]}}),_c('div',{staticClass:"shopping",class:{
                    active: _vm.selectedList.includes(`${i.value}-${picIndex}`),
                  },attrs:{"title":"add product"},on:{"click":function($event){return _vm.handleShopping(`${i.value}-${picIndex}`)}}},[_c('i',{class:_vm.selectedList.includes(`${i.value}-${picIndex}`)
                        ? 'el-icon-shopping-cart-full'
                        : 'el-icon-shopping-cart-2'})]),_c('div',{staticClass:"detail",attrs:{"title":"show detail"},on:{"click":function($event){$event.stopPropagation();return _vm.handleDetail(`${i.value}-${picIndex}`)}}},[_c('i',{staticClass:"el-icon-view"})])],1)}),0):_vm._e()])}),1)],1):_vm._e(),(!item.children && item.value === _vm.activeName)?_c('div',{staticClass:"card-list"},_vm._l((item.picNums),function(picIndex){return _c('div',{key:picIndex,staticClass:"card"},[_c('el-image',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.getNoChildrenImagePath(item.value, picIndex, true),"preview-src-list":[
              _vm.getNoChildrenImagePath(item.value, picIndex, false),
            ]}}),_c('div',{staticClass:"shopping",class:{
              active: _vm.selectedList.includes(`${item.value}-${picIndex}`),
            },on:{"click":function($event){return _vm.handleShopping(`${item.value}-${picIndex}`)}}},[_c('i',{class:_vm.selectedList.includes(`${item.value}-${picIndex}`)
                  ? 'el-icon-shopping-cart-full'
                  : 'el-icon-shopping-cart-2'})]),_c('div',{staticClass:"detail",attrs:{"title":"show detail"},on:{"click":function($event){$event.stopPropagation();return _vm.handleDetail(`${item.value}-${picIndex}`)}}},[_c('i',{staticClass:"el-icon-view"})])],1)}),0):_vm._e()])}),1),_c('product-detail-dialog',{attrs:{"dialogVisible":_vm.showDialog,"mainCategory":_vm.activeName,"subCategory":_vm.subActiveName,"product":_vm.activeProduct},on:{"update:dialogVisible":function($event){_vm.showDialog=$event},"update:dialog-visible":function($event){_vm.showDialog=$event},"add":_vm.handleShopping}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }