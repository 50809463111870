<template>
  <div class="about-wrap">
    <div class="about">
      <div class="title">WHO WE ARE</div>
      <div class="desc"
        >The Right Promotional Products Company in China since 2012. Since then
        the company holds a leading place in promotion gift industry. It is the
        remarkable achievement to take a foremost and well-known position in
        this very short period of time. We are very focused and ambitious to
        provide the cheapest but best quality products to our valuable
        customers.</div
      >
      <div class="desc"
        >The RPP is the market leader in the gifts & premium industry. We are
        professional in manufacturing with scalable factories and adopting,
        supply promotional products directly from China to the whole world.</div
      >
      <el-button
        style="width: 200px; margin: 20px 0 0 -47px; background: #009c00"
        type="primary"
        >More About Us</el-button
      >
      <!-- <div class="more">More About Us <i class="el-icon-right" /></div> -->
    </div>
    <div class="pic-wrap">
      <div class="pic"></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'About',
  };
</script>

<style lang="scss" scoped>
  .about-wrap {
    display: flex;
    justify-content: center;
    // width: 100%;
    // height: 100%;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 30px;
    background: #f7f7f7;
    border-radius: 5px;
    padding: 20px;
    .pic-wrap {
      display: flex;
      justify-content: left;
      height: 240px;
      .pic {
        width: 30rem;
        height: 240px;
        background: url('~@/assets/image/about/bg.png');
        background-size: 100% 100%;
        margin-top: 40px;
        margin-left: 10px;
      }
    }

    .about {
      width: 50rem;
      display: flex;
      flex-direction: column;
      justify-content: right;
      margin-right: 10px;
      .title {
        font-size: 20px;
        font-weight: 700;
        text-align: left;
        color: #009c00;
        margin-left: -46px;
      }
      .desc {
        text-align: left;
        color: #3b8043;
        margin-left: -46px;
        font-size: 18px;
      }
      .more {
        font-size: 20px;
        // font-weight: 700;
        text-align: right;
        color: #009c00;
        cursor: pointer;
      }
    }
  }
</style>
