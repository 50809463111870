<template>
  <div class="list-wrapper">
    <!-- 面包屑 -->
    <!-- <div class="breadcrumb">
      <div @click="showDetail = false" v-show="showDetail"
        ><i class="el-icon-back"
      /></div>
      <div @click="showDetail = false">HOME</div>
      <div v-show="showDetail">/</div>
      <div v-show="showDetail"> DETAIL</div>
    </div> -->
    <!-- 产品分类 -->
    <el-tabs v-model="activeName" tab-position="left" type="card">
      <el-tab-pane
        v-for="(item, index) in ALL_CATEGORIES"
        :key="index"
        :name="item.value"
        :label="item.label"
      >
        <span slot="label">
          <div class="panel-label">
            <span class="label">{{ `${item.label}（${item.picNums}）` }}</span>
            <div v-if="item.new" class="new"></div>
          </div>
        </span>
        <!-- 有子类 -->
        <div v-if="item.children" class="list">
          <el-tabs
            v-model="subActiveName"
            tab-position="top"
            style="height: 100%; overflow-x: auto"
            type="card"
          >
            <el-tab-pane
              v-for="(i, subIndex) in item.children"
              :key="subIndex"
              :name="i.value"
              :label="i.label"
            >
              <span slot="label">
                <div class="panel-label">
                  <span class="label">{{ `${i.label}（${i.picNums}）` }}</span>
                  <div v-if="i.new" class="new"></div>
                </div>
              </span>
              <div v-if="i.value === subActiveName" class="card-list">
                <!-- <div class="card-list"> -->
                <div v-for="picIndex in i.picNums" :key="picIndex" class="card">
                  <el-image
                    style="width: 100%; height: 100%"
                    :src="getImagePath(item.value, i.value, picIndex, true)"
                    :preview-src-list="[
                      getImagePath(item.value, i.value, picIndex, false),
                    ]"
                  >
                  </el-image>
                  <div
                    class="shopping"
                    :class="{
                      active: selectedList.includes(`${i.value}-${picIndex}`),
                    }"
                    title="add product"
                    @click="handleShopping(`${i.value}-${picIndex}`)"
                  >
                    <i
                      :class="
                        selectedList.includes(`${i.value}-${picIndex}`)
                          ? 'el-icon-shopping-cart-full'
                          : 'el-icon-shopping-cart-2'
                      "
                    />
                  </div>
                  <div
                    class="detail"
                    title="show detail"
                    @click.stop="handleDetail(`${i.value}-${picIndex}`)"
                  >
                    <i class="el-icon-view" />
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <!-- 无子类 -->
        <div
          v-if="!item.children && item.value === activeName"
          class="card-list"
        >
          <!-- <div v-else class="card-list"> -->
          <div v-for="picIndex in item.picNums" :key="picIndex" class="card">
            <el-image
              style="width: 100%; height: 100%"
              :src="getNoChildrenImagePath(item.value, picIndex, true)"
              :preview-src-list="[
                getNoChildrenImagePath(item.value, picIndex, false),
              ]"
            >
            </el-image>
            <div
              class="shopping"
              :class="{
                active: selectedList.includes(`${item.value}-${picIndex}`),
              }"
              @click="handleShopping(`${item.value}-${picIndex}`)"
            >
              <i
                :class="
                  selectedList.includes(`${item.value}-${picIndex}`)
                    ? 'el-icon-shopping-cart-full'
                    : 'el-icon-shopping-cart-2'
                "
              />
            </div>
            <div
              class="detail"
              title="show detail"
              @click.stop="handleDetail(`${item.value}-${picIndex}`)"
            >
              <i class="el-icon-view" />
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- 详情 -->
    <product-detail-dialog
      :dialogVisible.sync="showDialog"
      :mainCategory="activeName"
      :subCategory="subActiveName"
      :product="activeProduct"
      @add="handleShopping"
    />
  </div>
</template>

<script>
  import { ALL_CATEGORIES } from '../constants.js';
  import ProductDetailDialog from './ProductDetailDialog.vue';

  export default {
    name: 'ProductList',
    components: {
      ProductDetailDialog,
    },
    data() {
      return {
        tabPosition: 'left',
        activeName: '1',
        subActiveName: '1-1',
        ALL_CATEGORIES,
        selectedList: [],
        showDialog: false,
        activeProduct: null,
      };
    },
    watch: {
      activeName(val) {
        const tab = ALL_CATEGORIES.find(item => item.value === val);
        if (tab.children) {
          this.subActiveName = tab.children[0].value;
        } else {
          this.subActiveName = '';
        }
      },
      selectedList(val) {
        this.$emit('change-selected', val);
      },
    },
    methods: {
      getImagePath(itemValue, iValue, picIndex, isThumb) {
        // 注意：实际环境中，你可能需要根据实际情况调整路径
        return require(
          `../../assets/image/category/${itemValue}/${iValue}/${isThumb ? 'thumb/' : ''}${iValue}-${picIndex}.jpg`
        );
      },
      getNoChildrenImagePath(itemValue, picIndex, isThumb) {
        // 注意：实际环境中，你可能需要根据实际情况调整路径
        return require(
          `../../assets/image/category/${itemValue}/${isThumb ? 'thumb/' : ''}${itemValue}-${picIndex}.jpg`
        );
      },
      handleShopping(picIndex) {
        // console.log('in handleShopping...picIndex===', picIndex);
        const index = this.selectedList.findIndex(item => item === picIndex);
        this.$nextTick(() => {
          if (index === -1) {
            this.selectedList.push(picIndex);
          } else {
            this.selectedList.splice(index, 1);
          }
        });
      },
      handleDetail(picIndex) {
        this.activeProduct = picIndex;
        this.showDialog = true;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .list-wrapper {
    display: flex;
    flex-direction: column;
    .breadcrumb {
      display: flex;
      flex-direction: row;
      justify-content: left;
      line-height: 40px;
      margin-left: 12px;
      cursor: pointer;
      div {
        font-weight: 600;
        margin-right: 4px;
        &:hover {
          color: #28a745;
        }
      }
    }
    ::v-deep .el-tabs__header.is-left {
      width: 240px;
    }
    ::v-deep .el-tabs__item.is-active {
      color: #28a745;
    }
    ::v-deep .el-tabs--left.el-tabs--card .el-tabs__item.is-left.is-active {
      border-right-color: #e4e7ed;
    }
    ::v-deep .el-tabs--left.el-tabs--card .el-tabs__nav {
      border-right: 1px solid #e4e7ed;
    }
    ::v-deep .el-tabs__active-bar {
      background: #28a745;
    }
    ::v-deep .el-tabs--card > .el-tabs__header {
      border-bottom: none;
      margin-left: 10px;
    }
    .list {
      display: flex;
      flex-direction: row;
      justify-content: left;
      flex-wrap: wrap;
    }
    .card-list {
      display: flex;
      flex-direction: row;
      justify-content: left;
      flex-wrap: wrap;
      width: 100%;
      max-height: 1024px;
      scrollbar-width: none; /* Firefox */
      overflow-y: scroll; /* 强制显示垂直滚动条 */
      /* 保持可滚动 */
      &::-webkit-scrollbar {
        width: 0; /* Chrome, Safari, Edge */
      }
      .card {
        position: relative;
        width: 16.5rem; // 300px;
        height: 16.5rem; // 300px;
        margin: 10px;
        background-size: cover;
        background-position: center;
        border: 1px solid #ccc;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        .shopping {
          position: absolute;
          top: 0;
          right: 0;
          width: 30px;
          height: 30px;
          line-height: 30px;
          background: #fff;
          border-left: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
          text-align: center;
          // border-radius: 4px;
          cursor: pointer;
          i {
            color: #ccc;
          }
          &.active {
            background: #e6f2ea;
            i {
              color: #3b8043;
            }
          }
        }
        .detail {
          position: absolute;
          top: 30px;
          right: 0;
          width: 30px;
          height: 30px;
          line-height: 30px;
          background: #fff;
          border-left: 1px solid #ccc;
          border-top: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
          text-align: center;
          // border-radius: 4px;
          cursor: pointer;
          i {
            color: #ccc;
          }
          &.active {
            background: #e6f2ea;
            i {
              color: #3b8043;
            }
          }
        }
      }
    }
    .panel-label {
      position: relative;
      .label {
        padding-right: 20px;
      }
      .new {
        position: relative;
        top: -12px;
        left: -24px;
        display: inline-block;
        width: 22px;
        height: 10px;
        background: url(~@/assets/image/category/new.gif);
        background-size: 100% 100%;
      }
    }
  }
</style>
