<template>
  <div class="about-wrapper">
    <PageWrapper>
      <template slot="main"> About </template>
    </PageWrapper>
  </div>
</template>

<script>
  import PageWrapper from '../components/PageWrapper.vue';

  export default {
    name: 'About',
    components: {
      PageWrapper,
    },
  };
</script>

<style lang="scss" scoped>
  .about-wrapper {
    width: 100%;
    height: 100%;
  }
</style>
