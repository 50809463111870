<template>
  <el-dialog
    :title="
      subCategory
        ? `${mainCategoryObj.label} / ${subCategoryObj.label}`
        : `${mainCategoryObj.label}`
    "
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    width="40%"
  >
    <div class="content">
      <div class="pic">
        <!-- 当前大图 -->
        <img class="large" :src="bigImg" alt="" />
        <!-- 当前类别 -->
        <div class="list">
          <div class="list">
            <vue-seamless-scroll
              :data="allCategoryPic"
              :class-option="option"
              class="scroll-wrapper"
            >
              <img
                v-for="item in allCategoryPic"
                :key="item.picIndex"
                class="item"
                :src="item.picUrl"
                alt=""
                @click="handleClickPic(item.picIndex)"
              />
            </vue-seamless-scroll>
          </div>
        </div>
      </div>
      <div
        v-if="
          subCategoryObj && subCategoryObj.desc && subCategoryObj.desc.length
        "
        class="desc"
      >
        <div v-for="item in subCategoryObj.desc" :key="item" class="desc-item">
          {{ item }}
        </div>
      </div>
      <div v-else class="desc">
        <div class="item">
          <div class="label">Category</div>
          <div class="value">{{
            subCategory
              ? `${mainCategoryObj.label} / ${subCategoryObj.label}`
              : `${mainCategoryObj.label}`
          }}</div>
        </div>
        <div class="item">
          <div class="label">Tag</div>
          <div class="value">--</div>
        </div>
        <div class="item">
          <div class="label">Description</div>
          <div class="value">--</div>
        </div>
        <div class="item">
          <div class="label">Weight</div>
          <div class="value">--</div>
        </div>
        <div class="item">
          <div class="label">Dimensions</div>
          <div class="value">--</div>
        </div>
        <div class="item">
          <div class="label">Decoration</div>
          <div class="value">--</div>
        </div>
        <div class="item">
          <div class="label">Color</div>
          <div class="value">--</div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">CANEL</el-button>
      <el-button type="primary" @click="handleAdd"
        ><i class="el-icon-shopping-cart-full" style="margin-right: 8px" />ADD
        TO CART</el-button
      >
      <a
        class="content"
        style="display: inline-block; margin-left: 10px"
        href="mailto:miya@trppc.com.cn?subject=Hello The Right Promotional Products Company&body=Hello The Right Promotional Products Company!"
      >
        <el-button type="primary"
          ><i class="el-icon-message" style="margin-right: 8px" />CONTACT
          US</el-button
        ></a
      >
    </span>
  </el-dialog>
</template>

<script>
  import { ALL_CATEGORIES } from '../constants.js';
  import VueSeamlessScroll from 'vue-seamless-scroll';

  export default {
    name: 'ProductDetail',
    components: {
      VueSeamlessScroll,
    },
    props: {
      dialogVisible: {
        type: Boolean,
        default: false,
      },
      // main 1
      mainCategory: {
        type: String,
        default: '',
      },
      // sub 1-1
      subCategory: {
        type: String,
        default: '',
      },
      // 1
      product: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        currentProduct: '',
        option: {
          step: 1, // 数值越大速度滚动越快
          limitMoveNum: 1, // 开始无缝滚动的数据量
          hoverStop: true, // 是否开启鼠标悬停stop
          direction: 2, // 0向下 1向上 2向左 3向右
          openWatch: true, // 开启数据实时监控刷新dom
          singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
          singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
          waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
          loop: true, // 是否开启循环滚动
        },
      };
    },
    computed: {
      mainCategoryObj() {
        const mainCategory = ALL_CATEGORIES.find(
          item => item.value === this.mainCategory
        );
        return mainCategory;
      },
      subCategoryObj() {
        const mainCategory = ALL_CATEGORIES.find(
          item => item.value === this.mainCategory
        );
        if (mainCategory.children) {
          const subCategory = mainCategory.children.find(
            item => item.value === this.subCategory
          );
          return subCategory;
        } else return {};
      },
      allCategoryPic() {
        const pics = [];
        if (this.subCategoryObj && this.subCategoryObj.picNums) {
          for (let i = 1; i < this.subCategoryObj.picNums; i++) {
            pics.push({
              picIndex: i,
              picUrl: this.getImagePath2(
                this.mainCategory,
                this.subCategory,
                i,
                false
              ),
            });
          }
        } else {
          for (let i = 1; i < this.mainCategoryObj.picNums; i++) {
            pics.push({
              picIndex: i,
              picUrl: this.getNoChildrenImagePath(this.mainCategory, i, false),
            });
          }
        }
        return pics;
      },
      bigImg() {
        return this.getImagePath(
          this.mainCategory,
          this.subCategory,
          this.currentProduct || this.product,
          false
        );
      },
    },
    created() {
      this.currentProduct = this.product;
    },
    methods: {
      handleClose() {
        this.currentProduct = '';
        this.$emit('update:dialogVisible', false);
      },
      // 大图
      getImagePath(itemValue, iValue, picIndex, isThumb) {
        if (this.subCategory) {
          // 注意：实际环境中，你可能需要根据实际情况调整路径
          return require(
            `../../assets/image/category/${itemValue}/${iValue}/${isThumb ? 'thumb/' : ''}${picIndex}.jpg`
          );
        } else {
          // 注意：实际环境中，你可能需要根据实际情况调整路径
          return require(
            `../../assets/image/category/${itemValue}/${isThumb ? 'thumb/' : ''}${picIndex}.jpg`
          );
        }
      },
      getImagePath2(itemValue, iValue, picIndex, isThumb) {
        // 注意：实际环境中，你可能需要根据实际情况调整路径
        return require(
          `../../assets/image/category/${itemValue}/${iValue}/${isThumb ? 'thumb/' : ''}${iValue}-${picIndex}.jpg`
        );
      },
      getNoChildrenImagePath(itemValue, picIndex, isThumb) {
        // 注意：实际环境中，你可能需要根据实际情况调整路径
        return require(
          `../../assets/image/category/${itemValue}/${isThumb ? 'thumb/' : ''}${itemValue}-${picIndex}.jpg`
        );
      },
      handleAdd() {
        this.$emit('add', this.product);
        setTimeout(() => {
          this.handleClose();
        }, 500);
      },
      handleClickPic(picIndex) {
        console.log('in handleClickPic...picIndex===', picIndex, this.product);
        const pi = this.product.split('-');
        if (pi.length === 1) {
          this.currentProduct = `${pi[0]}-${picIndex}`;
        } else {
          this.currentProduct = `${pi[0]}-${pi[1]}-${picIndex}`;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .content {
    display: flex;
    .pic {
      .large {
        width: 400px;
        height: 400px;
        margin-right: 20px;
        border: 1px solid #808080;
      }
      .list {
        width: 400px;
        height: 120px;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        margin-top: 20px;
        .item {
          width: 96px;
          height: 96px;
          margin: 0 8px;
          display: inline-block;
          border: 1px solid #e5e5e5;
          cursor: pointer;
        }
      }
    }

    .desc {
      flex: 1;
      flex-direction: column;
      align-items: left;
      flex-wrap: wrap;
      word-wrap: break-word;
      .desc-item {
        display: flex;
        align-items: left;
        flex-wrap: wrap;
        // height: 32px;
        line-height: 32px;
        word-wrap: break-word;
      }
      .item {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 40px;
        line-height: 40px;
        .label {
          color: #808080;
        }
        .value {
          display: flex;
          flex-wrap: wrap;
          color: #000;
        }
      }
    }
  }
</style>
