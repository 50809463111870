<template>
  <div id="word-cloud"></div>
</template>

<script>
  import * as d3 from 'd3';
  import cloud from 'd3-cloud';

  export default {
    mounted() {
      this.$nextTick(() => {
        const words = [
          { text: 'Vue', weight: 10 },
          { text: 'JavaScript', weight: 8 },
          // 更多词汇...
        ];

        const svg = d3.select('#word-cloud');
        const width = window.innerWidth;
        const height = window.innerHeight;

        const layout = cloud()
          .size([width, height])
          .words(words)
          .padding(5)
          .rotate(() => Math.random() * 90 - 45) // 随机旋转角度
          .fontSize(word => word.weight)
          .on('end', this.draw(words));

        layout.start();
      });
    },
    methods: {
      draw(words) {
        const svg = d3.select('#word-cloud');
        const width = window.innerWidth;
        const height = window.innerHeight;
        console.log('Draw function called with words:', words);
        svg
          .append('g')
          .attr('transform', `translate(${width / 2},${height / 2})`) // 将画布中心化
          .selectAll('text')
          .data(words)
          .enter()
          .append('text')
          .style('font-size', d => `${d.size}px`)
          .style('fill', () => d3.hsl(Math.random() * 360, 0.7, 0.7))
          .attr(
            'transform',
            d => `translate(${[d.x, d.y]}) rotate(${d.rotate})`
          ) // 添加旋转
          .text(d => d.text);
      },
    },
  };
</script>

<style scoped>
  #word-cloud {
    position: relative;
    width: 1000px;
    height: 500px;
    perspective: 1000px; /* 添加透视效果 */
  }
</style>
