<template>
  <div class="product-wrapper">
    <PageWrapper>
      <template slot="main"> Product </template>
    </PageWrapper>
  </div>
</template>

<script>
  import PageWrapper from '../components/PageWrapper.vue';

  export default {
    name: 'Product',
    components: {
      PageWrapper,
    },
  };
</script>

<style lang="scss" scoped>
  .product-wrapper {
    width: 100%;
    height: 100%;
  }
</style>
