<template>
  <el-carousel :interval="4000" height="544px">
    <el-carousel-item v-for="item in 5" :key="item">
      <img :src="$data[`PIC000${item}`]" alt="" class="banner-img" />
    </el-carousel-item>
  </el-carousel>
</template>

<script>
  import PIC0001 from '@/assets/image/banner/0001.png';
  import PIC0002 from '@/assets/image/banner/0002.png';
  import PIC0003 from '@/assets/image/banner/0003.jpg';
  import PIC0004 from '@/assets/image/banner/0004.jpg';
  import PIC0005 from '@/assets/image/banner/0005.jpg';
  // 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
  export default {
    name: 'Banner',
    data() {
      return {
        PIC0001,
        PIC0002,
        PIC0003,
        PIC0004,
        PIC0005,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }
  // .el-carousel__item:nth-child(2n) {
  //   background-color: #99a9bf;
  // }

  // .el-carousel__item:nth-child(2n + 1) {
  //   background-color: #d3dce6;
  // }
  .el-carousel__item:nth-child(3) {
    background-color: rgba(143, 192, 132, 0.3); /* 半透明白色背景，可调整 */
    backdrop-filter: blur(100px);
  }
  .el-carousel__item:nth-child(4) {
    background-color: rgba(195, 66, 66, 0.2);
    backdrop-filter: blur(100px);
  }
  .el-carousel__item:nth-child(5) {
    background-color: rgba(183, 218, 196, 0.3);
    backdrop-filter: blur(100px);
  }
  .el-carousel__item:nth-child(6) {
    background-color: rgba(224, 191, 150, 0.5);
    backdrop-filter: blur(100px);
  }
  .el-carousel__item:nth-child(7) {
    background-color: rgba(207, 225, 223, 0.5);
    backdrop-filter: blur(100px);
  }
  .banner-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
</style>
