<template>
  <div class="header-wrap">
    <!-- 左侧logo区域 -->
    <div class="logo-wrap">
      <div class="logo">
        <img src="@/assets/logo.png" />
      </div>
      <div class="slogan">
        WELCOME TO THE RIGHT PROMOTIONAL PRODUCTS COMPANY !
      </div>
    </div>
    <!-- 右侧全局功能区域 -->
    <div class="website-nav">
      <!-- 全局导航 -->
      <div class="nav">
        <!-- HOME -->
        <div
          :class="['nav-item', { active: activeNav === 'HOME' }]"
          @click="handleClickTab({ name: 'HOME', id: 'home' })"
        >
          <i class="el-icon-s-home" />
          HOME
        </div>
        <!-- PRODUCTS -->
        <el-popover placement="bottom" width="968" trigger="hover">
          <!-- 所有分类 -->
          <div class="panel-all-category">
            <div
              v-for="item in ALL_CATEGORIES"
              :key="item.value"
              class="category"
            >
              <div class="main-category" @click="handleClickCategory(item)">
                <i class="el-icon-star-on" />
                <span>{{ `${item.label}（${item.picNums}）` }}</span>
                <span v-if="item.new" class="new"></span>
              </div>
              <div v-if="item.children">
                <div
                  v-for="i in item.children"
                  :key="i.value"
                  class="sub-category"
                  @click="handleClickCategory(i)"
                >
                  <span>{{ `-- ${i.label}（${i.picNums}）` }}</span>
                  <span v-if="i.new" class="new"></span>
                </div>
                ----------------------------
              </div>
            </div>
          </div>
          <div
            slot="reference"
            :class="['nav-item', { active: activeNav === 'PRODUCTS' }]"
            @click="handleClickTab({ name: 'PRODUCTS', id: 'product' })"
          >
            <i class="el-icon-s-goods" />
            PRODUCTS
            <i class="el-icon-arrow-down" />
          </div>
        </el-popover>
        <!-- ABOUT -->
        <div
          :class="['nav-item', { active: activeNav === 'ABOUT' }]"
          @click="handleClickTab({ name: 'ABOUT', id: 'about' })"
        >
          <i class="el-icon-user-solid" />
          ABOUT
        </div>
        <!-- <div
          v-for="item in navList"
          :key="item.name"
          :class="['nav-item', { active: activeNav === item.name }]"
          @click="handleClickTab(item)"
        >
          <i :class="item.icon" />
          {{ item.name }}
        </div> -->
      </div>
      <el-divider direction="vertical"></el-divider>
      <!-- 选择语言 -->
      <div class="language">
        <el-dropdown>
          <img class="lang-icon" :src="getLanguageSvgPath(adciveLanguage)" />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="item in languageList"
              :key="item.key"
              @click.native="handleChangeLang(item.key)"
            >
              <img class="lang-icon" :src="getLanguageSvgPath(item.key)" />
              <span>{{ item.name }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-divider direction="vertical"></el-divider>
      <!-- 购物车 -->
      <div class="shopping" @click="handleShowDrawer">
        <div class="count">{{ selectedNum }}</div>
        <div class="icon">
          <i class="el-icon-shopping-cart-full" />
        </div>
      </div>
      <el-divider direction="vertical"></el-divider>
      <!-- 联系我们 -->
      <div class="contact">
        <el-dropdown>
          <span class="el-dropdown-link">
            CONTACT US
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <a
                style="color: #28a745"
                href="mailto:miya@trppc.com.cn?subject=Hello The Right Promotional Products Company&body=Hello The Right Promotional Products Company!"
                >EMail</a
              >
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
  import { ALL_CATEGORIES } from './constants.js';

  export default {
    name: 'Header',
    props: {
      selectedNum: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        ALL_CATEGORIES,
        navList: [
          { name: 'HOME', icon: 'el-icon-s-home', id: 'home' },
          { name: 'PRODUCTS', icon: 'el-icon-s-goods', id: 'product' },
          { name: 'ABOUT', icon: 'el-icon-user-solid', id: 'about' },
        ],
        languageList: [
          { key: 'us', name: 'English', icon: 'us.svg' },
          { key: 'zh', name: '简体中文', icon: 'zh.svg' },
          { key: 'fr', name: 'Français', icon: 'fr.svg' },
          { key: 'de', name: 'Deutsch', icon: 'de.svg' },
          { key: 'it', name: 'Italiano', icon: 'it.svg' },
          { key: 'ja', name: '日本語', icon: 'ja.svg' },
          { key: 'br', name: 'Português', icon: 'br.svg' },
          { key: 'ru', name: 'Русский', icon: 'ru.svg' },
          { key: 'es', name: 'Español', icon: 'es.svg' },
          { key: 'ko', name: '한국어', icon: 'ko.svg' },
        ],
        activeNav: 'HOME',
        adciveLanguage: 'us',
      };
    },
    methods: {
      handleSelect(key, keyPath) {
        console.log(key, keyPath);
      },
      handleClickTab(item) {
        this.activeNav = item.name;
        this.$emit('scrollTo', item.id);
      },
      handleShowDrawer() {
        this.$emit('drawer');
      },
      getLanguageSvgPath(icon) {
        // 注意：实际环境中，你可能需要根据实际情况调整路径
        return require(`../assets/image/language/${icon}.svg`);
      },
      handleChangeLang(key) {
        this.adciveLanguage = key;
      },
      handleClickCategory(category) {
        this.$emit('list', category.value);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .header-wrap {
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    background-color: #e6f2ea;
    height: 54px;
    padding: 0 20px;
    color: #3b8043;
    z-index: 999;
    .logo-wrap {
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      .logo {
        display: flex;
        flex-direction: column;
        width: 40px;
        text-align: center;
        img {
          width: 40px;
          height: 40px;
        }
        span {
          font-size: 14px;
          color: #009c00;
        }
      }
      .slogan {
        font-size: 14px;
        color: #009c00;
        line-height: 40px;
        margin-left: 12px;
      }
    }
    .website-nav {
      display: flex;
      flex-direction: row;
      justify-content: right;
      .nav {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 18px;
        line-height: 54px;
        .nav-item {
          margin-right: 20px;
          cursor: pointer;
          i {
            font-size: 20px;
            margin-right: 8px;
          }
          &.active {
            color: #28a745;
            font-weight: 600;
            i {
              color: #28a745;
              font-weight: 600;
            }
          }
          &:hover {
            color: rgba(102, 186, 84, 1);
          }
        }
      }
      .language {
        display: flex;
        align-items: center;
        .lang-icon {
          width: 28px;
          height: 28px;
          cursor: pointer;
        }
      }
      .shopping {
        position: relative;
        cursor: pointer;
        .icon {
          display: inline-block;
          vertical-align: middle;
          line-height: 54px;
          font-size: 32px;
          font-weight: 600;
          color: #3b8043;
          :hover {
            color: #28a745;
          }
        }
        .count {
          position: absolute;
          top: 4px;
          right: -8px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20px;
          height: 20px;
          border-radius: 20px;
          background: #28a745;
          color: #fff;
          font-size: 12px;
        }
      }
      .contact {
        line-height: 54px;
        margin-right: 20px;
      }
      ::v-deep .el-divider--vertical {
        margin: auto 20px;
        background: rgba(59, 128, 67, 0.2);
      }
    }
  }
</style>
<style lang="scss">
  .lang-icon {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  .panel-all-category {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 968px;
    height: 680px;
    .category {
      width: 242px;
      font-weight: 600;
      .main-category {
        position: relative;
        line-height: 24px;
        font-size: 14px;
        cursor: pointer;
        i {
          color: #009c00;
          margin-right: 4px;
        }
        &:hover {
          color: #009c00;
        }
      }
      .sub-category {
        font-weight: 400;
        line-height: 22px;
        font-size: 12px;
        cursor: pointer;
        &:hover {
          color: #009c00;
        }
      }
      .new {
        position: relative;
        top: -10px;
        left: -8px;
        display: inline-block;
        width: 22px;
        height: 10px;
        background: url(~@/assets/image/category/new.gif);
        background-size: 100% 100%;
      }
    }
  }
</style>
